import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgHttpCachingService } from 'ng-http-caching';
import { Offer } from 'src/app/models/offer';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Contract } from 'src/app/models/contract';
import { ContractRequest } from 'src/app/models/contract-request';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private http = inject(HttpClient);
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  });
  private ngHttpCachingService = inject(NgHttpCachingService);

  public postOffer(offer: Offer) {
    this.ngHttpCachingService.clearCacheByRegex(/offers/);
    return this.http.post<Offer>(
      environment.matchMakingApiUrl + '/v1/offers',
      offer,
      { headers: this.header }
    );
  }

  public putOffer(offer: Offer) {
    this.ngHttpCachingService.clearCacheByRegex(/offers/);
    return this.http.put<Offer>(
      environment.matchMakingApiUrl + '/v1/offers',
      offer,
      { headers: this.header }
    );
  }

  public setOfferStatus(id: string, offerStatus: Offer['status']) {
    this.ngHttpCachingService.clearCacheByRegex(/offers/);
    return this.http.patch<Offer>(
      environment.matchMakingApiUrl + `/v1/offers/${id}/status`,
      { status: offerStatus },
      { headers: this.header }
    );
  }

  public removeOffer(id: string) {
    this.ngHttpCachingService.clearCacheByRegex(/offers/);
    return this.http.delete(
      environment.matchMakingApiUrl + `/v1/offers/${id}`,
      { headers: this.header }
    );
  }

  public getOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>(
      environment.matchMakingApiUrl + '/v1/offers',
      { headers: this.header }
    );
  }

  public getOfferById(id: string): Observable<Offer> {
    return this.http.get<Offer>(
      environment.matchMakingApiUrl + `/v1/offers/${id}`,
      { headers: this.header }
    );
  }

  public getOffersForInquiry(inquiryId: string): Observable<Offer[]> {
    return this.http.get<Offer[]>(
      environment.matchMakingApiUrl + `/v1/offers/inquiry/${inquiryId}`
    );
  }

  public acceptOffer(
    id: string,
    contactId: string,
    origin: string,
    type: string
  ): Observable<Contract> {
    this.ngHttpCachingService.clearCacheByRegex(/offers/);
    this.ngHttpCachingService.clearCacheByRegex(/inquiries/);
    this.ngHttpCachingService.clearCacheByRegex(/containers/);
    this.ngHttpCachingService.clearCacheByRegex(/waste-streams/);

    const url = `${environment.matchMakingApiUrl}/v1/offers/${id}/accept?contactId=${contactId}&origin=${origin}&type=${type}`;

    return this.http.patch<Contract>(url, { headers: this.header });
  }

  public rejectOffer(id: string): Observable<Offer> {
    this.ngHttpCachingService.clearCacheByRegex(/offers/);
    return this.http.patch<Offer>(
      environment.matchMakingApiUrl + `/v1/offers/${id}/reject`,
      { headers: this.header }
    );
  }

  public requestContractByContractId(
    contractId: string,
    companyId: string
  ): Observable<ContractRequest> {
    this.ngHttpCachingService.clearCacheByRegex(/offers/);
    const headers = this.header.set('company.id', companyId);
    return this.http.patch<any>(
      `${environment.matchMakingApiUrl}/v1/offers/contract/${contractId}/request`,
      { headers }
    );
  }
}
